.inputError {
  border: var(--borderErrorField);
}

input:focus {
  background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: white !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}