.container {
  background-color: rgba(217, 217, 217, 1);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 18px;
}

.countContainer {
  background-color: rgba(249, 245, 245, 1);
  width: 22px;
  height: 15px;
  font-family: var(--fontFamilyHelmet);
  font-size: 9px;
  font-weight: 400;
  line-height: 11.16px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: var( --colorDarkBlack);

}

.smallButton {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13px;
  width: 13px;
  margin: 0 !important;
}

.smallButton:hover {
  background-color: rgba(255, 255, 255, 0.25);
}