@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: h6 from global;
  color: var(--colorWhite);
  text-align: center;

  margin-left: 4px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px;
  border: 2px solid green;
  background-color: transparent;
  width: auto;
  height: auto;
  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 100%;
  background-color: green;

}
