@import url('../../styles/customMediaQueries.css');


.addToCartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantityCountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 14px 0;
  margin-top: 12px;
}

.smallButton,
.countContainer {
  display: inline-block;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallButton {
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: var( --colorWhite);
  border: 0.5px solid var(--colorLocagoSoftWhite);
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
}

.smallButton:hover {
  background-color: black;
  color: white;
}

.smallButton:active {
  background-color: black;
  color: white;
  transition: background-color 0.08s ease, color 0.08s ease;
}

.countContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  width: 40px;
  height: 40px;
  background-color: var( --colorWhite);
  border: 0.5px solid var(--colorLocagoSoftWhite);
  border-left: none;
  border-right: none;
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
}

.countWrapper {
  border: 0.5px solid var(--colorLocagoSoftWhite);
  position: absolute;
}

.bigButton {
  background-color: white;
  border-radius: var(--borderRadiusButton);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 700;
  line-height: 27.28px;
  color: black;
  border: 1px solid black;
  width: 100%;
  padding: 12px 0;
  transition: background-color 0.6s ease, color 0.6s ease;
  cursor: pointer;
  padding-bottom: 11px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.bigButton:hover {
  background-color: var(--colorLightYellow);
  color: black;
}

.bigButton:active {
  background-color: var(--colorLightYellow);
  color: black;
  transition: background-color 0.08s ease, color 0.08s ease;
}

.bigButton:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  border-color: #d3d3d3;
  cursor: not-allowed;
}


.unlikedButton {
  background-color: white;
  border-radius: var(--borderRadiusButton);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  line-height: 27.28px;
  color: black;
  border: 1px solid black;
  width: 100%;
  padding: 12px 0;
  transition: background-color 0.6s ease, color 0.6s ease;
  cursor: pointer;
  margin-top: 14px;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.unlikedButton:active {
  background-color: var( --colorWhite);
  color: black;
  transition: background-color 0.08s ease, color 0.08s ease;
}

.unlikedButton:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  border-color: #d3d3d3;
  cursor: not-allowed;
}

.likedButton {
  background-color: var(--colorLightYellow);
  color: black;
  border: solid 1px black;
  width: 100%;
  padding: 12px 0;
  transition: background-color 0.5s ease, color 0.6s ease;
  cursor: pointer;
  margin-top: 14px;
  border-radius: var(--borderRadiusButton);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  line-height: 27.28px;
  padding-bottom: 11px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }

}

.likedButton svg path {
  fill: black !important;
}

.likedButton:active {
  background-color: var(--colorLightYellow);
  color: black;
  border: solid 1px black;
  transition: background-color 0.08s ease, color 0.08s ease;
}

.unlikedButton:hover {
  background-color: var(--colorLightYellow);
  color: black;
  border: solid 1px black;
}


.likedButton:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  border-color: #d3d3d3;
  cursor: not-allowed;
}

.cartLabel {
  font-weight: bold;
  color: black;
}

.cartLabel span {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: 19.84px;
  text-align: left;
  text-transform: uppercase;

}
.maxItemsText {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 22px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: red;
  padding: 0;
  margin:0;
  margin-top: 10px;
}