@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
    }
  }

  position: relative;
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: 400;
  font-size: var(--fontSizeDescription);
  line-height: normal;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription);
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: 400;
  color: var(--colorGrey700);
  font-size: var(--fontSizeDescription);
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  color: black;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription);
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: 400;
  font-size: var(--fontSizeDescription);
  color: black;
  line-height: normal;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription); 
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.likeButton {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: all 0.5s ease;
  position: relative;
}

.aspectRatioWrapper:hover .likeButton {
  background-color: #00000038;
}

.likeButton button {
  background-color: white;
  border-radius: 20px;
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: normal;
  color: black;
  border: 1px solid #E4E4E4;
  padding: 8px 20px;
  transition: background-color 0.6s ease, color 0.6s ease;
  cursor: pointer;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transition: all 0.5s ease;
  opacity: 0;
  gap: 5px;
  border: 1px solid black;
}

.aspectRatioWrapper:hover .likeButton button {
  opacity: 1;
  backdrop-filter: blur(10px);
}

.likeButton button:hover {
  background-color: var(--colorLightYellow);
  color: black;
  border: solid 1px black;
}

.likeSvg {
  height: 18px;
}

.likeSvg path {
  stroke: #973132 !important;
}

.likeButton button:hover .likeSvg path {
  fill: #973132 !important;
  stroke: #973132 !important;
}