@import url('../../styles/customMediaQueries.css');

.container {
  padding: 50px 40px 0;
  background-color: var( --colorWhite);
  font-family: var(--fontFamilyHelmet);

  @media (--viewportMaxExtraMedium) {
    padding-inline: 20px;
  }
}

.storySections {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 67px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.textSection {
  max-width: 480px;
  width: 100%;
}

.heading {
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeHeading);
  font-style: normal;
  font-weight: 700;
  line-height: 55.8px;
  letter-spacing: 0.9px;
  margin: 0;
  padding: 0;
}

.description {
  margin: 23px 0 0;
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeDescription);
  font-style: normal;
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  letter-spacing: 0.36px;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
}

.imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.storeImage {
  width: 569px;
  /* width: 603px; */
  height: 376px;
  border-radius: 120px;
  border-bottom: 15px solid var(--colorLightYellow);
  border-top: 1px solid var(--colorLightYellow);
  border-inline: 1px solid var(--colorLightYellow);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  left: -23px;
  object-fit: cover;

  @media (max-width: 1380px) {
    position: unset;
    left: unset;
  }
  @media (--viewportMaxMedium) {
    border-radius: 80px;
  }
}

@media (--viewportxMaxlarge) {
  .storeImage {
    max-width: 520px;
  }
}

@media (max-width: 1023px) {
  .storySections {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .textSection {
    max-width: 100%;
  }
}

@media (--viewportMaxMedium) {
  .heading {
    font-size: 36px;
  }
}

@media (--viewportMaxExtraMedium) {
  .heading {
    font-size: 24px;
    line-height: 27px;
  }

  .storeImage {
    width: 100%;
    height: 320px;

    @media (max-width: 375px) {
      height: 200px;
    }
  }

  .description {
     font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}