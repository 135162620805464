@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    margin-left: 40px;
  }
}

.tabContent {
  composes: a from global;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  /* Font */
  font-family: var(--fontFamilyHelmet);
  font-size: 45px;
  font-weight: 700;
  line-height: 55.8px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;


  color: var( --colorDarkBlack);
  padding-bottom: 10px;

  border-bottom-width: 6px;
  padding-right: 31px;
  padding-bottom: 17px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  @media (--viewportMaxMedium) {
    padding-right: 10px;
    padding-bottom: 5px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportMedium) {
    border-bottom-width: 4px;
    padding: 0 13px;
    padding-bottom: 15px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}