@import url('../../styles/customMediaQueries.css');

.container {
  position: relative;
  padding: 0 100px;
  margin-top: 36px;
  @media (--viewportMedium) and (--viewportExtraMaxlarge) {
    padding: 0 40px;
  }
  @media (--viewportMaxMedium) {
    padding: 0 20px;
  }
}
h1 {
  font-family: var(--fontFamilyHelmet);
  font-size: 40px;
  font-weight: 400;
  line-height: 44.38px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}
p {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: 24.41px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  margin: 20px 0;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    line-height: normal;
    margin: 14px 0;
  }
}
