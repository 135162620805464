@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
}

.dayLabel {
  composes: textSmall from global;
  margin: 0;
  line-height: var(--lineHeightBase);
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.paymentAlignment {
  display: flex;
  align-items: center;
  margin-top: 10px !important;
  font-family: var(--fontFamilyHelmet);

  @media (max-width: 1200px) {
    margin-top: 31px !important;
  }

  @media (max-width: 1023px) {
    margin-top: 0px !important;
  }
}

.maxWidthPayment {
  width: 100%;
  max-width: 236px;
}

.dayInfo,
.totalPrice {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.2px;

  margin: 0 !important;
  padding: 0;
}

.totalPrice {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 22px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: black;
  width: fit-content !important;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
  }
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  /* flex-direction: row;
  align-items: flex-end; */
  /* justify-content: space-between; */

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
}


.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 !important;
  width: 100% !important;
  max-width: 236px;
  text-align: left;
}
.totalLabel span {
  font-weight: 700 !important;
}
.customMargin {
  margin-top: 0px !important;
}


.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

/* Order details page */
.orderLine {
  margin-top: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #000000;
  width: 100%;
}

.totalOrderDetails {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: auto;
  justify-content: end;
}

.totalTitle {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: var( --colorDarkBlack);
  margin: 0;
  padding: 0;
  width: 11%;
}

.totalPrice {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: var( --colorDarkBlack);
  margin: 0;
  padding: 0;
}

.orderTotalMain {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.grandTotal {
  margin-top: 4px;
  font-family: var(--fontFamilyHelmet);
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  justify-content: end;
  gap: 20px;
}

.grandTotalTitle {
  margin: 0;
  padding: 0;
  font-size: var(--fontSizeDescription);
  font-family: var(--fontFamilyHelmet);
  font-weight: 700;
  line-height: 19.84px;
  text-align: left;
  color: var(--colorDarkBlack);
  width: 11%;

  @media (--viewportMaxMedium) {
    width: 100px;
    font-size: var(--fontSizeBase);
  }
}

.subTitle {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: var(--colorDarkBlack);
  width: 11%;

  @media (--viewportMaxMedium) {
    width: 100px;
    font-size: var(--fontSizeBase);
  }
}
.subTaxTitle {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: var(--colorDarkBlack);
  width: 11.6%;

  @media (--viewportMaxMedium) {
    width: 100px;
    font-size: var(--fontSizeBase);
  }
}

.grandTotalPrice {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: 19.84px;
  text-align: left;
  color: var(--colorDarkBlack);
}

.subTotalPrice {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: var(--colorDarkBlack);

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
  }
}

.orders {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.orderItemMain {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}