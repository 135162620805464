@import url('../../styles/customMediaQueries.css');

.container {
  width: 100%;
  font-family: var(--fontFamilyHelmet);
  background-color: white;

}

.reviewsMainContainer {
  padding: 0 24px;

  @media (--viewportMaxExtraMedium) {
    padding: 0 0px;
  }
}

.reviewsSection {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;

}

.listingPageReviewSection {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  padding-top: 40px;

  @media (--viewportLarge) {
    padding: 65px 20px 0 20px;
    padding-top: 104px;
  }
}

.reviewSectionHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.listingPageReviewSection  .reviewSectionHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.listingPageReviewSection  .reviewSectionHead h2 {
  color: var( --colorDarkBlack);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeHeading);;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  @media (--viewportMaxMedium) {
     font-size: var(--fontSizeSubHeading);
     line-height: var(--lineHeightBase);
  }
}
.reviewsSection h2 {
  color: var( --colorDarkBlack);
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeHeading);;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.44px;
    margin: 0;
  padding: 0;

  @media (--viewportMaxExtraMedium) {
     font-size: var(--fontSizeSubHeading);
       }
}

.reviewsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: 20px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
.listingPageReviewSection  .reviewsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 23px;
  row-gap: 18px;
  margin-top: 20px;

  @media (--viewportLarge) {
    padding: 0px;
  }
  @media (--viewportMaxMedium) {
    grid-template-columns: 1fr;
  }
}

.review {
  max-width: 541px;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #e4e4e4;
}

.listingPageReviewSection  .reviewsContainer .review {
  max-width: 100%;
  width: 100%;
  padding: 30px 47px;
  border: 1px solid black;
  border-radius: 15px;
  @media (--viewportMaxLarge) {
    padding: 15px 20px;
  }
}
.profile {
  display: flex;
  gap: 16px;
}
.listingPageReviewSection  .reviewsContainer .review .profile {
  display: flex;
  gap: 8px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.listingPageReviewSection  .reviewsContainer .review .profile .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileDetails {
  width: calc(100% - 76px);
  display: flex;
  flex-direction: column;
}

.profileName {
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeDescription);
  font-style: normal;
  font-weight: 700;
  line-height: 19.84px;
  margin: 0;
}
.listingPageReviewSection  .reviewsContainer .review .profile .profileName {
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeSubText);
  font-style: normal;
  font-weight: 400;
  line-height: 19.84px;
  margin: 0;
}

.reviewDate {
  color: var( --colorDarkBlack);
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
  text-align: left;
  margin: 4px 0 0;
}
.listingPageReviewSection  .reviewsContainer .review .profile .reviewDate {
  color: var( --colorDarkBlack);
  font-family: var(--fontFamilyHelmet);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
  text-align: left;
  margin: 0px 0 0;
}

.reviewRating svg {
  display: block !important;
}

.reviewRating {
  margin-top: 5px;
  color: #f39c12;
  display: flex;
  align-items: center;
}

.reviewRating span {
  margin-left: 5px;
  color: #333;
}

.reviewText {
  color: var( --colorDarkBlack);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.ratingStatusText {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}
.listingPageReviewSection  .reviewsContainer .review .profile .ratingStatusText {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.ratingStatusText p {
  font-size: var(--fontSizeSmall);
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  line-height: 14.88px;
  color: var( --colorDarkBlack);
}

.listingPageReviewSection  .reviewsContainer .review .profile .ratingStatusText p {
  font-size: var(--fontSizeSubText);
  margin: 0;
  padding: 0;
  font-family: var(--fontFamilyHelmet);
  line-height: 19.88px;
  color: var( --colorDarkBlack);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
  margin-bottom: 36px;
}

.listingPagePagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 5px;
  margin-top: 30px;
  margin-bottom: 36px;
  border: 1px solid black;
  width: 97px;
  padding: 11px 10px;
  border-radius: 50px;
  cursor: pointer;
}
.listingPagePagination button:disabled {
  opacity: 0.5;
}
.listingPagePagination .arrow1 {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.listingPagePagination .arrow2 {
  border: none;
  rotate: 180deg;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.listingPagePagination .arrow1 img {
  width: 30px;
}
.listingPagePagination .arrow2 img {
  width: 30px;
}

.pagination button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #d9d9d9;
  color: var( --colorDarkBlack);
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
}

.pagination button:disabled {
  cursor: not-allowed;
}

.pagination button.active {
  border: 1px solid #000;
}

.arrow:first-child svg {
  margin-top: 5px;
  margin-right: 6px;
}

.arrow:last-child svg {
  margin-bottom: 7px;
  margin-left: 6px;
}

.reviewRatingStar {
  height: 16px;
  width: 16px;
  margin-right: 2px;
  color: #FF9C72;

  &:last-of-type {
    margin-right: 0;
  }
}

.reviewTextStar {
  color: var( --colorDarkBlack);
  position: relative;
  top: 4px;
}

.mobileReviewRating {
  margin-bottom: 0px;
}

@media (max-width: 1023px) {
  .reviewsContainer {
    grid-template-columns: auto;
  }

  .review {
    max-width: 100%;
  }
}

@media (--viewportMaxExtraMedium) {
  .container {
    padding: 0 20px;
  }

  .reviewsContainer {
    padding-left: 0;
  }
}

.sortBy {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 4px 6px;
}

.sortBy p {
   font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: var(--lineHeightBase);
  text-align: left;
  color: black;
  margin: 0;
  padding: 0;
  width: fit-content;
  font-family: var(--fontFamilyHelmet);
  text-transform: uppercase;
}

.sortBy select {
  width: 100px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: var(--lineHeightBase);
  text-align: left;
  font-family: var(--fontFamilyHelmet);
  text-transform: uppercase;
}