@import url('../../styles/customMediaQueries.css');

.root {
  margin-top: 30px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 41px;
  }
}

.selectError {
  border: var(--borderErrorField);
}