@import url('../../../styles/customMediaQueries.css');

.footer {
  padding: 70px 20px;
  background: #F5F3F1;
  width: 100vw;
  @media (--viewportMaxExtraSmall) {
   padding: 30px 20px;
  }
}

.footerContent {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  gap: 20px;
  margin: 0px;
  margin-bottom: 6px;
  @media (--viewportMaxMedium) {
    flex-direction: column;
    padding: 0px;
  }
  @media (--viewportExtraMaxlarge ) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.footerLogo {
  width: 200px;
  margin-bottom: 1px;
  justify-content: flex-start;
}

.newsLetter {
  margin: 0;
  padding: 0 !important;
  font-family: var(--fontFamilyHelmet);
  font-size: 31px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: center;
  white-space: nowrap;
  justify-content: flex-start;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeSubHeading);
  }
}

.inputGroup {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  width: 100%;
  max-width: 445px;
  height: 45px;
  border: 1px solid black;
  border-radius: 50px;
  overflow: hidden;
  margin: 0 36px;
  @media (--viewportMaxExtraMedium) {
    margin: 0;
    max-width: 100%;
  }
}

.inputGroup input {
  display: flex;
  border: none;
  font-family: var(--fontFamilyHelmet);
  font-size: 24px;
  padding: 6px 24px;
  padding-top: 9px;
  height: 100%;
  margin-top: 0;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    padding: 6px 10px;
    padding-top: 8px;
  }
}
.inputGroup input::placeholder {
  color: black;
  text-transform: uppercase;
}

.subscribeButton {
  display: flex;
  background-color: var(--colorLightYellow);
  color: black;
  padding: 6px 28px;
  border: none;
  cursor: pointer;
  font-family: var(--fontFamilyHelmet);
  font-weight: 500;
  font-size: 24px;
  font-stretch: ultra-expanded;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  height: 100%;
  padding-top: 9px;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    padding: 6px 10px;
    padding-top: 8px;
  }
}

.socialIcons {
  display: flex;
  flex-direction: row;
  gap: 25px;
  font-size: 1.5px;
  @media (--viewportMaxMedium) {
    gap: 15px;
  }
}
.socialIcons a img {
  width: 51px;
  height: 51px;

  @media (--viewportMaxMedium) {
    width: 44px;
    height: 44px;
  }
}
.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.7; 
  flex-wrap: wrap;
  @media (--viewportMaxExtraSmall) {
    flex-direction: column;
    justify-content: center;
  }
  @media (--viewportMaxMedium) {
    justify-content: center;
  }
}

.links a {
  font-size: 24px;
  font-family: var(--fontFamilyHelmet);
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.05em;
  padding: 10px 20px;
  text-align: left;
  @media (--viewportMaxLarge) {
     font-size: var(--fontSizeDescription);
    line-height: normal;
  }
  @media (--viewportMaxMedium) {
    padding: 10px;
  }
}
.footerContainer {
  width: 100%;
  max-width: 1282px;
  margin: 0 auto;
  @media (--viewportMaxMedium) {
    max-width: 100%;
  }
}

