@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0px;
  }

  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportMaxLarge) {
    margin-top: 0;
    padding: 0;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.modalHeading h1 {
  font-size: 30px;
  color: var(--colorDarkBlack);

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeButton);
  }
}

.sizeHr {
  border: 0;
  height: 1px;
  background-color: #d9d9d9;
}

.size {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.color {
  margin: 10px 0 30px 0;
}

.colorRadio {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 12px 0;
}

.colorLabel {
  display: flex;
  align-items: center;
}

.colorLabel span {
  margin-right: 5px;
  color: black;
  font-size: var(--fontSizeButton);
}

.size {
  display: flex;
  align-items: center;
  margin: 6px 0;
}

.size span {
  color: black;
  font-size: var(--fontSizeButton);
}

.sizeInput {
  width: 93px;
  height: 34px;
  margin: 6px 0;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.sizeInput.unavailable {
  opacity: 0.5;
  cursor: not-allowed;
}

.sizeInput.selected {
  border: 2px solid black;
  z-index: 1;
}

.sizeInput.unavailable {
  width: 93px;
  height: 34px;
  opacity: 0.5;
  cursor: not-allowed;
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: 400;

  color: var(--marketplaceColor);
  margin: 0;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeHeading);
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  margin-top: 6px;

  @media (--viewportMedium) {
    margin-left: 0;
    padding: 0;
    margin-top: 12px;
  }

  @media screen and (max-width: 1023px) {
    margin-left: 0;
  }

  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: block;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderHeading a {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 700;
  line-height: var(--lineHeightDescription);
  text-align: left;
  color: black;
  text-decoration: underline;
  text-transform: uppercase;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

.orderHeading h1 {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeHeading);
  font-weight: 400;
  line-height: 43.4px;
  text-align: left;
  color: var(--colorDarkBlack);
  border: none;
  text-transform: capitalize;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;
  padding-bottom: 20px !important;
  border-bottom: 0.5px solid #E4E4E4;

  @media (--viewportMedium) {
    padding: 4px 0;
    padding-bottom: 28px !important;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.modalTitle {
  font-size: var(--fontSizeSubHeading);
  line-height: 27px;
  font-weight: 700;
  color: var(--colorDarkBlack);
  font-family: var(--fontFamilyHelmet);

  @media screen and (max-width: 628px) {
    font-size: var(--fontSizeDescription);
  }
}

.cartItem {
  padding-top: 25px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.cartProductImage {
  width: 171px;
  height: 192px;
}

.cartProductImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cartProductInfo {
  font-size: var(--fontSizeDescription);
  line-height: 22px;
  color: var(--colorDarkBlack);
  font-family: var(--fontFamilyHelmet);

  @media screen and (max-width: 628px) {
    font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}
.cartProductInfo div {
  font-size: var(--fontSizeDescription);
  line-height: 22px;
  color: var(--colorDarkBlack);
  font-family: var(--fontFamilyHelmet);

  @media screen and (max-width: 628px) {
    font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}

.cartModalbutton {
  max-width: 370px;
  width: 100%;
}

.cartModalbutton button {
  width: 100%;
  height: 51px;
  font-size: var(--fontSizeSubHeading) !important;
  line-height: 27px !important;
  font-weight: 700 !important;
  font-family: var(--fontFamilyHelmet);
  border: 1px solid black !important;
  transition: all 0.5s ease;

  @media screen and (max-width: 628px) {
    font-size: 18px !important;
    line-height: normal !important;
  }
}
.cartModalbutton button:hover {
  background-color: var(--colorWhite);
  color: black;
  border: 1px solid black !important;
}
.recommendationSection {
  margin-top: 42px;
}

.recommendationTitle {
  font-size: var(--fontSizeButton);
  line-height: var(--lineHeightBase);
  font-weight: 700;
  font-family: var(--fontFamilyHelmet);
  color: var(--colorDarkBlack);

  @media screen and (max-width: 628px) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}

.recommendedItems {
  margin-top: 21px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;

  @media screen and (min-width: 530px) {
    display: none;
  }
}

.listingCard {
  width: 135px;
}

.recommendedItemsMain {
  width: 137px !important;
  margin-right: 20px !important;

  @media (--viewportMedium) {
    margin-right: 0px !important;
  }

  @media screen and (min-width: 481px) and (max-width: 628px) {
    margin-right: 5px !important;
  }

  @media screen and (--viewportExtraSmall) and (max-width: 530px) {
    margin-right: 39px !important;
  }

  @media (--viewportMaxExtraSmall) {
    margin-right: 0px !important;
  }

  @media (--viewportMaxMedium) {
    width: 100px !important;
  }
}

.recommendedItemsImage {
  width: 100%;
}

.recommendedItemsImage img {
  width: 100%;
  height: 127px;
  object-fit: cover;
  margin-bottom: 10px;
}



.recommendedItemsData {
  width: 100%;
  display: block !important;
}

.recommendedItemsData h4 {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  text-transform: uppercase;
  line-height: 17.36px;
  text-align: left;
  color: var(--colorDarkBlack);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recommendedItemsData p {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
  text-align: left;
  color: var(--colorDarkBlack);
  text-transform: capitalize;
  margin: 0;
  text-overflow: ellipsis;
}

.recommendedItemsData .price {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubText);
  font-weight: 400;
  line-height: 17.36px;
  text-align: left;
  color: var(--colorDarkBlack);
  margin: 0;
  display: block !important;
}

.leftArrowBtn {
  position: absolute;
  left: -10px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  z-index: 999;

  @media screen and(max-width: 1024) {
    position: relative;
  }
}

.rightArrowBtn {
  position: absolute;
  right: -10px;
  border: none;
  background: none;
  padding: 0;
  z-index: 999;
  cursor: pointer;

  @media (--viewportMaxLarge) {
    position: relative;
  }
}

.recommendedSlider {
  width: 91%;
  height: 250px;
  margin: 0 auto;

  @media screen and (max-width: 418px) {
    width: 100%;
    left: 27px;
  }

  @media screen and (min-width: 419px) and (max-width: 530px) {
    width: 100%;
  }
}

.recommendedSlider div div {
  display: flex;
  position: relative !important;

  @media screen and (min-width: 419px) and (max-width: 530px) {
    gap: 11px;
    justify-content: center;
  }

  @media screen and (max-width: 418px) {
    gap: 10px;
    justify-content: start;
  }
}

.recommededListingDesktop {
  display: none;

  @media screen and (min-width: 530px) {
    display: flex;
    margin-top: 21px;
    gap: 0px;
  }

  @media (--viewportMedium) {
    gap: 72px;
  }
}
