@import url('../../styles/customMediaQueries.css');

.faqsContainer {
  position: relative;
  padding: 0 100px;
  margin-top: 36px;
  @media (--viewportMedium) and (--viewportExtraMaxlarge) {
    padding: 0 40px;
  }
  @media (--viewportMaxMedium) {
    padding: 0 20px;
  }
}
.faqsContainer h1 {
  font-family: var(--fontFamilyHelmet);
  font-size: 40px;
  font-weight: 400;
  line-height: 44.38px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 18px;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}
.faqsContainer h5 {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeSubHeading);
  font-weight: 400;
  line-height: 24.41px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
    line-height: normal;
    margin-bottom: 10px;
  }
}
.faqsContainer p {
  font-family: var(--fontFamilyHelmet);
  font-size: 18px;
  font-weight: 400;
  line-height: 19.97px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeSubText);
    line-height: normal;
    margin-bottom: 20px;
  }
}
