@import url('../../styles/customMediaQueries.css');

.productOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.optionGroupColor {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 0.5px solid #E4E4E4;
  padding-bottom: 30px;
  padding-top: 14px;
}

.optionGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 0.5px solid #E4E4E4;
  padding: 24px 0;
}

.label {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: 19.84px;
  text-align: left;
  color: black;
  margin-top: 0px;
  text-transform: uppercase;
}

.colorName {
  font-weight: 400;
  text-transform: capitalize;
}

.colors {
  display: flex;
  gap: 24px;
}

.bordercolorOption {
  border: 1px solid #E4E4E4;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorBorderOption {
  border: 1px solid transparent;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bordercolorOption.selected {
  border-color: black;

}

.colorOption {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.colorBorderOption.selected {
  border-color: black !important;
  width: 34px;
  height: 34px;
}

.colorBorderOption.selected .colorOption {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0px solid transparent;
  cursor: pointer;
}

.colorButton {
  border: none;
  cursor: pointer;
}

.colorButton:disabled {
  cursor: not-allowed;
  color: #ccc;
  text-decoration: line-through;
  opacity: 0.4;
}

.sizes {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.sizeOption {
  padding: 8px 16px;
  border: 1px solid #e3e3e3;
  background: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-family: var(--fontFamilyHelmet);
  min-width: 93px;
  border-radius: 60px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
  }
}

.sizeOption.selected {
  border-color: black;
  color: black;
}

.sizeOption:disabled {
  cursor: not-allowed;
  color: #ccc;
}

.notAvailable {
  color: #e4e4e4;
  cursor: not-allowed;
  position: relative;
}

.borderMain {
  display: none;
}

.notAvailable .borderMain {
  display: block;
}

.notAvailable .borderMain::before {
  content: '';
  position: absolute;
  top: 20px;
  right: -4px;
  width: 107%;
  height: 1px;
  background-color: #e4e4e4;
  transform: rotate(338deg);
}

.notAvailableColor {
  opacity: 0.2;
}