@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportMedium) and (--viewportMaxLarge) {
    margin-top: 31px;
  }
  @media (--viewportMaxMedium) {
    margin-top: 7px;
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.sideNav {
  /* Layout */
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  justify-content: center;

  @media (--viewportLarge) {
    padding: 55px 20px;
    flex-direction: column;
    justify-content: flex-start;
    width: 362px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding-right: 0;
    display: flex;
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 48px 0 82px calc((100% - 1056px) / 2); */
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.sideNav a {
  width: 100%;
  background-image: none;
  padding-right: 30px;
  padding-left: 67px;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 700;
  line-height: var(--lineHeightDescription);
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  color: var( --colorDarkBlack);
}

.sideNav a:hover {
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 98px) center;
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}