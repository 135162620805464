@import url('../../styles/customMediaQueries.css');

.LocagoButton {
  background-color: var(--colorLightYellow);
  color: black !important;
  border: solid 1px black;
  width: 100%;
  padding: 10px 14px 10px 10px;
  border-radius: var(--borderRadiusButton);
  transition: background-color 0.6s ease, color 0.6s ease;
  cursor: pointer;
  opacity: 1;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.LocagoButton:active {
  background-color: var( --colorWhite);
  color: black;
  transition: background-color 0.08s ease, color 0.08s ease;
}

.LocagoButton--inverted {
  background-color: var( --colorWhite);
  color: black;
}

.LocagoButton--inverted:active {
  background-color: var(--colorLightYellow);
  color: black;
  border: 1px solid black;
}

.LocagoButton--big {
  height: auto;
  width: 355px;
  font-size: var(--fontSizeButton) !important;
  border-radius: var(--borderRadiusButton);
  @media (--viewportMaxMedium) {
    height: auto;
    font-size: 18px !important;
  }
}

.bottomNavigateButtons .LocagoButton--inverted:hover {
  background-color: var(--colorLightYellow);
  color: black;
  border: 1px solid black;
}