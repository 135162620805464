@import url('../../styles/customMediaQueries.css');

.cartModal {
    padding: 24px 20px 38px;
    max-width: 669px;
    width: 100%;
    height: calc(100% - 30px);
    overflow: auto;

    @media (--viewportMaxMedium) {
        max-width: calc(100% - 20px);
    }
}

.closeButton {
    padding: 0;
    float: right;
    position: relative;
    z-index: 999;
}

.modalBody {
    padding-top: 40px;
    padding-left: 37px;
    padding-right: 43px;
    position: relative;

    @media screen and (min-width:629px) and (--viewportMaxMedium) {
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 20px;
    }

    @media screen and (max-width:628px) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
    }
}