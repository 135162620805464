@import url('../../styles/customMediaQueries.css');

.container {
    width: 100%;
    background-color: #e4e4e463;
    border-bottom: 1px solid black;
    font-family: var(--fontFamilyHelmet);
    padding-inline: 40px;

    @media (--viewportMaxExtraMedium) {
        padding-inline: 20px;
    }
}

.profileSections {
    max-width: 1248px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: end;
    gap: 165px;
    padding-top: 0px;
    position: relative;
    font-family: var(--fontFamilyHelmet);

    @media (viewportxMaxlarge) {
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 600px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    @media (max-width: 430px) {
        margin-top: 130px !important;
    }
}

.profileSection {
    width: 490px;
    position: relative;

    @media (--viewportMaxExtraSmall) {
        width: 100% !important;
        max-width: 490px;
    }
}

.redSlop {
    width: 100%;
    height: 203px;
    background-color: var(--colorLightYellow);
    border-radius: 150px 150px 0px 0px;
    padding: 0 75px;
    display: flex;
    justify-content: center;
    align-items: end;
}

.profilePicture {
    border-radius: 120px 120px 0 0;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    max-width: 339px !important;
    width: 100%;
    height: 336px !important;

    @media (max-width: 430px) {
        width: 90%;
        height: 313px !important;
    }
}

.avatarImage {
    width: 100%;
    height: 100%;
}

.profileName {
    padding: 8px 24px;
    border-radius: 8px;
    background-color: #9731327a;
    font-weight: 700;
    text-align: center;
    font-size: var(--fontSizeDescription);
    color: var( --colorWhite);
    margin: 0 0 16px 0;
    z-index: 1;
    font-family: var(--fontFamilyHelmet);
}

.storeInfoSection {
    max-width: 515px;
    width: 100%;
    padding-top: 41px;
    padding-bottom: 61px;

    @media (max-width: 1340px) {
        max-width: fit-content;
    }

    @media (max-width: 480px) {
        padding-bottom: 92px;
    }


    @media (--viewportMaxLarge) {
        margin-top: 0px !important;
        padding-block: 35px !important;
        padding-bottom: 100px !important;
    }
}

.storeName {
    color: var( --colorDarkBlack);
    font-size: var(--fontSizeHeading);
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0.9px;
    margin: 0;
    padding: 0;
}

.storeDetails {
    margin-top: 22px;
    width: 100%;

    @media (--viewportMedium) {
        max-width: 477px;
    }
}

.storeDetails .storeDetailsMain {
    font-family: var(--fontFamilyHelmet);
    font-size: var(--fontSizeDescription);
    font-weight: 700;
    line-height: var(--lineHeightDescription);
    letter-spacing: var(--letterSpacingDescription);
    text-align: left;
}

.storeDetailsData {
    word-break: break-word;
}

.storeDetails p,
div {
    margin: 0;
    color: var( --colorDarkBlack);
    font-size: var(--fontSizeDescription);
    font-style: normal;
    font-weight: 700;
    line-height: var(--lineHeightBase);
    letter-spacing: 0.36px;
    padding: 0;
    font-family: var(--fontFamilyHelmet);
}

.storeDetailsData {
    font-weight: 400 !important;
}

.storeDetails a {
    color: var( --colorDarkBlack);
    text-decoration: none;
}

.storeDetails a:hover {
    text-decoration: underline;
}

.socialMediaIcons p {
    margin: 0;
    color: var( --colorDarkBlack);
    font-size: var(--fontSizeDescription);
    font-style: normal;
    font-weight: 700;
    line-height: var(--lineHeightBase);
    letter-spacing: 0.36px;
    padding: 0;
    text-decoration: underline;
    font-family: var(--fontFamilyHelmet);
}

.socialIcons {
    margin-top: 16px;
}

.socialIcons a {
    margin-right: 8px;
}

.favouriteContainer {
    width: 100px;
    height: 100px;
    background-color: var( --colorWhite);
    border: 1px solid #9ca3af;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -28px;
    bottom: 27px;
    font-family: var(--fontFamilyHelmet);

    @media (max-width:1025px) {
        top: auto !important;
        right: 12px !important;
    }

}

.favouriteContainer {
    @media (max-width:600px) {
        top: auto !important;
        right: -9px !important;
    }
}

.favouriteContainerLg {
    @media (--viewportMaxLarge) {
        display: none;
    }
}

.favouriteContainerSm {
    @media (min-width: 1025px) {
        display: none;
    }
}

.storeLogo {
    width: 100%;
    height: 100%;
    border-radius: 52px;
}

.favouriteName {
    text-align: center;
    color: var( --colorDarkBlack);
    text-transform: lowercase;
    font-size: var(--fontSizeBase);
    font-weight: 700;
    margin: 0;
    font-family: var(--fontFamilyHelmet);
    height: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.favouriteButton {
    width: auto;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    bottom: -12px;
    border: 0;
    cursor: pointer;
    padding: 0 6px;
}
.favouriteButton svg {
    width: 18px;
    height: 18px;
    margin: 0 auto;
}

.favouriteButton:hover svg path {
    fill: black;
    width: 18px;
    height: 18px;
    margin: 0 auto;
}

@media (max-width: 1380px) {
    .profileSections {
        gap: 120px;
    }

    .favouriteContainer {
        right: -16px;
    }
}

@media (max-width: 1284px) {

    .favouriteContainer {
        right: -16px;
        top: auto;
    }
}

@media (viewportxMaxlarge) {
    .profileSections {
        gap: 80px;
    }
}

@media (--viewportMaxLarge) {
    .profileSections {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
        margin-top: 180px;
    }

    .storeInfoSection {
        position: relative;
        max-width: 100%;
        padding-top: 60px;
    }

    .favouriteContainer {
        bottom: 20px;
        right: 0;
    }
}

@media (--viewportMaxMedium) {
    .storeInfoSection {
        max-width: 100%;
    }

    .storeName {
        font-size: 36px;
    }
}

@media (--viewportMaxExtraMedium) {
    .redSlop {
        padding: 0 25px;
    }

    .profileSections {
        padding-left: 20px;
        padding-right: 20px;
    }

    .profileName {
         font-size: var(--fontSizeSubText);
        padding: 6px 12px;
    }

    .storeName {
        font-size: 24px;
        line-height: 27px;
    }

    .storeDetails p {
         font-size: var(--fontSizeSubText);
        line-height: normal;
    }
}